var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeMenu === "premium"
    ? _c(
        "section",
        [
          _c(
            "div",
            { staticClass: "flex items-center justify-between" },
            [
              _c("h2", { staticClass: "my-2" }, [_vm._v("作品毎の分析データ")]),
              _c(
                "router-link",
                {
                  staticClass:
                    "px-4 py-3 rounded text-center font-bold bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                  attrs: { to: _vm.premiumLinkPath },
                },
                [_vm._v(" 全体に戻る ")]
              ),
            ],
            1
          ),
          _vm.isPremium
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "rounded-md border shadow p-2" },
                    [_c("AccessChart", { attrs: { logs: _vm.logs } })],
                    1
                  ),
                  _c("AccessLogTable", {
                    staticClass: "mt-4",
                    attrs: { logs: _vm.logs },
                  }),
                ],
                1
              )
            : [_c("PremiumAd")],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }