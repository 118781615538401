var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container mx-auto movie_menu" }, [
      _c("div", { staticClass: "my-3 flex items-center justify-between" }, [
        _c(
          "button",
          {
            staticClass: "py-2 px-3 font-semibold hover:text-black",
            class: [_vm.activeMenu == "outline" ? "active" : "text-brown-grey"],
            on: {
              click: function ($event) {
                return _vm.changeActivePage("outline")
              },
            },
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("commons.movie_tabs.outline")))])]
        ),
        _c(
          "button",
          {
            staticClass: "py-2 px-3 font-semibold hover:text-black",
            class: [_vm.activeMenu == "detail" ? "active" : "text-brown-grey"],
            on: {
              click: function ($event) {
                return _vm.changeActivePage("detail")
              },
            },
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("commons.movie_tabs.detail")))])]
        ),
        _c(
          "button",
          {
            staticClass: "py-2 px-3 font-semibold hover:text-black",
            class: [_vm.activeMenu == "format" ? "active" : "text-brown-grey"],
            on: {
              click: function ($event) {
                return _vm.changeActivePage("format")
              },
            },
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("commons.movie_tabs.format")))])]
        ),
        _c(
          "button",
          {
            staticClass: "py-2 px-3 font-semibold hover:text-black",
            class: [_vm.activeMenu == "cast" ? "active" : "text-brown-grey"],
            on: {
              click: function ($event) {
                return _vm.changeActivePage("cast")
              },
            },
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("commons.movie_tabs.casts")))])]
        ),
        _c(
          "button",
          {
            staticClass: "py-2 px-3 font-semibold hover:text-black",
            class: [_vm.activeMenu == "gallery" ? "active" : "text-brown-grey"],
            on: {
              click: function ($event) {
                return _vm.changeActivePage("gallery")
              },
            },
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("commons.movie_tabs.gallery")))])]
        ),
        _c(
          "button",
          {
            staticClass: "py-2 px-3 font-semibold hover:text-black",
            class: [_vm.activeMenu == "award" ? "active" : "text-brown-grey"],
            on: {
              click: function ($event) {
                return _vm.changeActivePage("award")
              },
            },
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("commons.movie_tabs.award")))])]
        ),
        _vm.loginUserRole === "provider"
          ? _c(
              "button",
              {
                staticClass:
                  "py-2 px-3 font-semibold hover:text-black bg-off-yellow rounded-full",
                class: [_vm.activeMenu == "premium" ? "active" : "text-white"],
                on: {
                  click: function ($event) {
                    return _vm.changeActivePage("premium")
                  },
                },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("commons.movie_tabs.premium"))),
                ]),
              ]
            )
          : _vm._e(),
        _vm.loginUserRole == "manager"
          ? _c(
              "button",
              {
                staticClass: "py-2 px-3 font-semibold hover:text-black",
                class: [
                  _vm.activeMenu == "data" ? "active" : "text-brown-grey",
                ],
                on: {
                  click: function ($event) {
                    return _vm.changeActivePage("data")
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("commons.movie_tabs.data")))])]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }